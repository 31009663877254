import CartModalStyle from "./CartStyle";
import CustomPortalModal from "../CustomPortalModal/CustomPortalModal";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../utils";
import CartItemCard from "../CartItemCard/CartItemCard";
import Button from "../Button";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getUserData } from "../../../services/helper";
import { clearCartAction, fetchCartAction } from "../../../stateManagement/reactQuery/actions/portalActions";
import { useNavigate } from "react-router-dom";
import { total } from "../../../utils/helpers/total";
import toCurrency from "../../../utils/helpers/toCurrency";
import EmptyInstance from "../EmptyInstance/EmptyInstance";
import CustomLoader from "../CustomLoader";


const CartModal = ({ price, insurrance }) => {
    const queryClient = useQueryClient();

    const company_id = JSON.parse(getUserData())?.company_id;
    const currency = JSON.parse(getUserData())?.currency;
    const user_id = JSON.parse(getUserData())?.id;

    const navigate = useNavigate();
    const { setCounter } = useContext(AppContext);

    const { openCart, setOpenCart } = useContext(AppContext);
    const [cart, setCart] = useState([]);
    const [sum, setSum] = useState();
    const [spinner, setSpinner] = useState(false);

    const { data: cart_data } = useQuery(['cart_items', company_id],
        fetchCartAction,
        {
            refetchOnWindowFocus: false,
        }
    );

    // const { refetch: clearCart } = useQuery(['cart_items', company_id, cart?.id, user_id],
    //     clearCartAction,
    //     {
    //         refetchOnWindowFocus: false,
    //         enabled: false
    //     }
    // );

    const {mutateAsync: clearCart } = useMutation((variables) => clearCartAction(variables),
    {
      onSuccess: (response) => {
        const { data : {status} } = response;

        if(status === "success"){
            setCart([]);
            setCounter(0);
            queryClient.invalidateQueries('cart_items');
        }
      }
          
    }
  );


  const handleClearCart = async () => {
    try {
        setSpinner(true);
        await clearCart({company_id, cart_id: cart?.id, user_id})

    } catch (error) {
        setSpinner(false)
    }finally {
        setSpinner(false)
    }
  }

    useEffect(() => {
        if (cart_data?.data?.cart) {
            setCart(cart_data?.data?.cart)
        }
    }, [cart_data]);

    useEffect(() => {
        if (cart && cart?.assets) {
            let prices = [];
            const current_count = JSON.parse(cart?.assets).length;
            setCounter(current_count);


            JSON.parse(cart?.assets)?.map(({ quantity, price }) => prices.push(price * quantity));

            setSum(prices.reduce((a, b) => a + b, 0))

        }

    }, [cart]);

    const handleGotoNextpage = () => {
        setOpenCart((prev) => !prev)

        if (cart && cart?.assets && !JSON.parse(cart?.assets)?.length < 1) {
            navigate('/portal/overview/checkout');
        } else {
            navigate('/portal/marketplace/main_store');
        }

    };

    const totalPrice = cart && cart?.assets && !JSON.parse(cart?.assets)?.length < 1 && total(JSON.parse(cart?.assets));
    // const currency = dashboardData?.onboardedAssets?.currency;


    return (
        <CustomPortalModal open={openCart} setOpen={setOpenCart} hasHeader_image headerText_title={"Your Cart"} headerText_subtitle="Manage IT inventory with our shopping cart">
            <CartModalStyle>
                <section className="order_items_area">
                    {/* cart && !JSON.parse(cart?.assets)?.length < 1 */}
                    {
                        cart && cart?.assets && !JSON.parse(cart?.assets)?.length < 1 ? JSON.parse(cart?.assets).map(({ asset_id, quantity, asset_name, price, asset_image_url }, index) => (
                            <CartItemCard key={index} asset_id={asset_id} asset_name={asset_name} price={price * quantity} quantity={quantity} currency={currency} cart_id={cart?.id} company_id={company_id} image={asset_image_url} />
                        )) : (
                            <EmptyInstance
                                text_only
                                title={"Your cart is empty!"}
                                sub_title={"Add your favorite items to your cart."}
                            />
                        )
                    }




                </section>
                <section className="cost_area">
                    {/* {
                        price && (
                            <div className="list_items">
                                <h3>Price</h3>
                                <p>₦ 2,838,000</p>
                            </div>
                        )
                    } */}

                    {/* {
                        insurrance && (
                            <div className="list_items">
                                <h3>Insurance</h3>
                                <p>₦ 278,000</p>
                            </div>
                        )
                    } */}

                    {
                        currency && totalPrice && (
                            <div className="list_items">
                                <h3 className="total">Total</h3>
                                <p>{currency && toCurrency(sum, currency)}</p>
                            </div>
                        )
                    }


                </section>
                <section className="description_area">
                    <h3>Asset Protection</h3>
                    <p>All assets purchased through the website will have insurance coverage in case of any damage or loss during transit, after delivery and duration of use.</p>
                </section>

                {/* <Button disabled={!(cart && cart?.assets && !JSON.parse(cart?.assets)?.length < 1)} bg_default handleClick={() => {
                    clearCart().then(async response => {
                        if (response.status === 'success') {
                            setCart([]);
                            setCounter(0);
                        }
                    });
                }}>
                    Clear Cart
                </Button> */}
                <section className="order_items_footer">
                    {
                        !spinner ? (
                            <Button disabled={!(cart && cart?.assets && !JSON.parse(cart?.assets)?.length < 1)} bg_default handleClick={handleClearCart}>
                                Clear Cart
                            </Button>
                        ) : (
                            <div style={{margin: "auto"}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <CustomLoader/>
                                </div>
                            </div>
                        )
                    }
                    
                    {'\u00A0'}
                    <Button bg_blue={true} handleClick={handleGotoNextpage}>
                        {
                            cart && cart?.assets && !JSON.parse(cart?.assets)?.length < 1 ? "Checkout" : "Continue Shopping"
                        }

                    </Button>
                </section>
            </CartModalStyle>
        </CustomPortalModal>
    )
}

export default CartModal;