import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import * as EmailValidator from 'email-validator';
import { AppContext } from '../../../utils';
import CustomModal from '../../shared/CustomModal/CustomModal';
import { Button, CustomInput, CustomLoader, CustomSelect } from '../../shared';
import { EditEmpoyeeModalStyle } from './EditEmpoyeeModalStyle';
import { employee_contact_icon } from '../../../assets/icons';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { editEmployeeAction } from '../../../stateManagement/reactQuery/actions/portalActions';




const EditEmpoyeeModal = ({company_id, employee_id, first_name, last_name, role, employee_type, email}) => {
    const queryClient = useQueryClient();

    const { openEditempoyee, setOpenEditEmployee } = useContext(AppContext);
    const [isDisAbled, setIsDisAbled] = useState(true);
    const [spinner, setSpinner] = useState(false);

    const {mutateAsync: handleEditEmployee } = useMutation((variables) => editEmployeeAction(variables),
    {
      onSuccess: (response) => {
        const { data : {status} } = response;
        

        if(status === "success"){
          setSpinner(false);
          toast.success(`Successfully updated `);

          queryClient.invalidateQueries('single_employee');
          queryClient.invalidateQueries('all_employee');
          setOpenEditEmployee((prev) => !prev)
        }
      },

      onError: (error) => {
        toast.error(`Something went wrong: ${error.message}`)
      }
          
    }
  );

    const formik = useFormik({
		initialValues: {
			first_name: "",
			last_name: "",
            email: "",
			role: "",
			employee_type: "",
		},
		validationSchema: Yup.object().shape({
			first_name: Yup.string()
			.required("First name is required"),
			last_name: Yup.string()
			.required("Las name is required"),
			email: Yup.string().email()
			.required("Email address is required"),
            role: Yup.string()
			.required("Role is required"),
			employee_type: Yup.string()
			.required("Employee type is required"),
		}),

		onSubmit: async(values)  => {
            try {
                setSpinner(true);

                const formData = {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    role: values.role,
                    employee_type: values.employee_type,
                    email: values.email
                }

                await handleEditEmployee({company_id, employee_id, formData})

            } catch (error) {
                setSpinner(false)
            }finally {
                setSpinner(false)
            }
        },
    })

    useEffect(() => {
        if(formik.values.email !== "" && EmailValidator.validate(formik.values.email) &&
          formik.values.first_name !== "" && formik.values.last_name !== "" &&
          formik.values.employee_type !== "" && formik.values.role ) {
            setIsDisAbled(false)
          }else {
            setIsDisAbled(true)
        }
    
      }, [
        formik.values.email,
        formik.values.first_name,
        formik.values.last_name,
        formik.values.employee_type,
        formik.values.role
      ])

      useEffect(() => {
        formik.setFieldValue("first_name", first_name)
        formik.setFieldValue("last_name", last_name)
        formik.setFieldValue("role", role)
        formik.setFieldValue("email", email)
        formik.setFieldValue("employee_type", employee_type)
      }, [
        first_name,
        last_name,
        role,
        email,
        employee_type])
      
  return (
    <CustomModal portal open={openEditempoyee} hasHeader setClose={setOpenEditEmployee} image={employee_contact_icon} >
        <EditEmpoyeeModalStyle onSubmit={formik.handleSubmit}>
            <div className='header_text'>
                <h1>Employee info</h1>
                <p>Edit your employee information here.</p>

                <hr/>
            </div>

            

            <div className='form_container'>
                <div className='form_field_container'>
                    <section className='aside_left'>
                        <CustomInput 
                            label={"First Name"}
                            name="first_name"
                            type="text"
                            placeholder={"Dinka"}
                            value={formik.values.first_name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                        >
                            {formik.touched.first_name && formik.errors.first_name ? <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.first_name}</div> : null}
                        </CustomInput>
                    </section>
                    <section className='aside_right'>
                        <CustomInput 
                            label={"Last Name"}
                            name="last_name"
                            type="text"
                            placeholder={"Bankole"}
                            value={formik.values.last_name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                        >
                            {formik.touched.last_name && formik.errors.last_name ? <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.last_name}</div> : null}
                        </CustomInput>
                    
                    </section>
                </div>
                <CustomInput 
                    label={"Email Address"}
                    name="email"
                    type="email"
                    placeholder={"dinkabankole@leads.com"}
                    value={formik.values.email}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                >
                    {formik.touched.email && formik.errors.email ? <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.email}</div> : null}
                </CustomInput>
                <CustomInput 
                    label={"Role"}
                    name="role"
                    type="text"
                    placeholder={"Project Manager"}
                    value={formik.values.role}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                >
                    {formik.touched.role && formik.errors.role ? <div style={{color: 'red', fontSize: '12px'}}>{formik.errors.role}</div> : null}
                </CustomInput>
                <CustomSelect
                    label={"Employee Type"}
                    optionData={['Contract', 'Full time', 'Freelance', 'Other']}
                    isDefault
                    defaultValue={[{label: `${employee_type}`, value: `${employee_type}`}]}
                    placeholder={"Select employee type"}
                    handleChange={async({ value }) => formik.setFieldValue("employee_type", value)}
                    handleBlur={formik.handleBlur}
                />
                <div className='meta'/>
                    {
                        !spinner ? (
                            <div className='ce_btn_container'>
                                <div className='btn_container'>
                                    <div className='btn'>
                                        <Button
                                            bg_default={true}
                                            type={"button"}
                                            handleClick={() => setOpenEditEmployee((prev) => !prev)}
                                        >
                                        Cancel
                                        </Button>
                                    </div>
                                    <div className='btn'>
                                        <Button
                                            bg_blue={true}
                                            type={"submit"}
                                            disabled={isDisAbled}
                                        >
                                            Save Changes
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                        <div style={{margin: "auto"}}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <CustomLoader/>
                            </div>
                        </div>
                        )

                    }
                </div>

            
        </EditEmpoyeeModalStyle>
  

    </CustomModal>
  )
}

export default EditEmpoyeeModal